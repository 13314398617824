<template>
  <div>
    <custom-snackbar ref="snackbar" />
    <!-- Formulário -->
    <v-form ref="form" @submit.prevent="handleFormSubmit">
      <v-row>
        <v-col class="col-12 col-sm-8">
          <v-card class="mb-5">
            <v-card-title> Dados gerais </v-card-title>
            <v-card-text>
              <v-row class="mt-2">
                <v-col class="py-1 col-6">
                  <EmpresasSelect
                    obrigatorio
                    v-model="dadosGerais.empresa"
                    :disabled="allDisable"
                  />
                </v-col>

                <v-col class="py-1 col-6">
                  <ProducoesSelect
                    obrigatorio
                    administrativo
                    :empresa="dadosGerais.empresa"
                    v-model="dadosGerais.producao"
                    :disabled="allDisable"
                  />
                </v-col>

                <v-col class="py-1 col-6">
                  <v-text-field
                    dense
                    outlined
                    disabled
                    persistent-placeholder
                    v-model="dadosGerais.numeroOrdemCompra"
                    label="N° OS"
                  />
                </v-col>

                <v-col class="py-1 col-6">
                  <v-select
                    dense
                    outlined
                    persistent-placeholder
                    label="Situação"
                    :items="situacaoOptions"
                    item-text="label"
                    item-value="id"
                    disabled
                    v-model="dadosGerais.situacao"
                  />
                </v-col>

                <v-col class="py-1 col-6">
                  <DatePicker
                    :label="'Data do Serviço'"
                    v-model="dadosGerais.dataSolicitacao"
                    disabled
                  />
                </v-col>

                <v-col
                  class="py-1 col-6"
                  >
                  <v-select
                    dense
                    outlined
                    persistent-placeholder
                    label="Condições de Pagamento"
                    :items="condicoesDePagamentoOptions"
                    item-text="label"
                    item-value="id"
                    v-model="dadosGerais.condicoesPagamento"
                    :rules="[
                      (v) =>
                        !!v ||
                        'Condições de Pagamento Obrigatória',
                    ]"
                    :disabled="allDisable"
                  />
                </v-col>

                <v-col
                  class="py-1 col-6">
                  <DatePicker
                    label="Data Emissão NF/RPA"
                    v-model="dadosGerais.dataEmissaoNF"
                    :rules="[
                      (v) =>
                        !!v ||
                        'Data Emissão NF Obrigatória',
                    ]"
                    :disabled="allDisable || fornecedor.rpa"
                  />
                </v-col>

                <v-col
                  class="py-1 col-6"
                >
                  <DatePicker
                    label="Data Vencimento NF"
                    v-model="dadosGerais.dataVencimentoNF"
                    :rules="[
                      (v) =>
                        !!v ||
                        'Data Vencimento NF Obrigatória',
                    ]"
                    :min="
                      adicionaSeteDiasVencimentoNF(dadosGerais.dataSolicitacao)
                    "
                    :disabled="allDisable"
                  />
                </v-col>

                <v-col class="py-1 pb-5 col-12">
                  <v-textarea
                    outlined
                    persistent-placeholder
                    :label="'Justificativa'"
                    value=""
                    v-model="dadosGerais.observacoes"
                    :rows="2"
                    :rules="[
                      (v) =>
                        !!v ||
                        'Justificativa Obrigatória',
                    ]"
                    :disabled="allDisable"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col class="col-12 col-sm-4">
          <v-card class="mb-5">
            <v-card-title> Responsáveis </v-card-title>

            <v-card-text>
              <v-row class="mt-2">
                <v-col class="py-1 col-12">
                  <v-text-field
                    dense
                    outlined
                    disabled
                    persistent-placeholder
                    label="Digitação"
                    v-model="responsaveis.solicitacao"
                  />
                </v-col>

                <v-col class="py-1 col-12">
                   <UsuarioSelect
                    :empresa="dadosGerais.empresa"
                    :producao="dadosGerais.producao"
                    v-model="responsaveis.compra"
                    obrigatorio
                    :disabled="allDisable || dadosGerais.producao <= 0"
                  />
                </v-col>

                <v-col class="py-1 col-6">
                  <v-text-field
                    dense
                    outlined
                    disabled
                    persistent-placeholder
                    label="Validação Nv. 1"
                    v-model="responsaveis.aprovacaoNv1"
                  />
                </v-col>

                <v-col class="py-1 col-6">
                  <v-text-field
                    dense
                    outlined
                    disabled
                    persistent-placeholder
                    label="Validação Nv. 2"
                    v-model="responsaveis.aprovacaoNv2"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="mb-5">
            <v-card-title> Fornecedor </v-card-title>

            <v-card-text>
              <v-row class="mt-2">
                <v-col class="py-1 col-12">
                  <FornecedorSelect
                    :empresa="dadosGerais.empresa"
                    :producao="dadosGerais.producao"
                    v-model="fornecedor.fornecedor"
                    obrigatorio
                    :disabled="allDisable || dadosGerais.producao <= 0"
                  />
                </v-col>

                <v-col :class="[dadosGerais.tipoDocumento && 'py-1 col-6']"
                >
                  <v-select
                    dense
                    outlined
                    persistent-placeholder
                    label="Tipo de Documento"
                    :items="tipoDocumentoOptions"
                    item-text="label"
                    item-value="id"
                    :rules="[
                      (v) =>
                        !!v ||
                        'Tipo de Documento é Obrigatória',
                    ]"
                    v-model="dadosGerais.tipoDocumento"
                    :disabled="allDisable"
                  />
                </v-col>

                <v-col class="py-1 col-6" v-if="dadosGerais.tipoDocumento === '0'">
                  <v-text-field
                    dense
                    outlined
                    persistent-placeholder
                    label="N° NF"
                    v-model="fornecedor.numeroNotaFiscal"
                    :rules="[
                      (v) =>
                        !!v ||
                        'N° Obrigatório',
                    ]"
                    :disabled="allDisable"
                  />
                </v-col>

                <v-col class="py-1 col-6" v-if="dadosGerais.tipoDocumento === '1'">
                  <RPASelect
                      :empresa="dadosGerais.empresa"
                      :producao="dadosGerais.producao"
                      :fornecedor="fornecedor.fornecedor"
                      :data-emissao="dadosGerais.dataEmissaoNF"
                      v-model="fornecedor.rpa"
                      obrigatorio
                      :disabled="allDisable || !fornecedor.fornecedor"
                  />
                </v-col>

              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Serviços -->
      <v-card class="mb-5">
        <v-card-title>
          Serviços
        </v-card-title>

        <v-card-text>
          <v-row
            v-for="(servico, index) in servicos"
            :key="'servicos-' + index"
            class="mt-2"
            dense
          >
            <v-col>
              <ServicoSelect
                :empresa="dadosGerais.empresa"
                :producao="dadosGerais.producao"
                v-model="servico.servico"
                :disabled="!dadosGerais.producao || allDisable"
                @change="servicoSelect(servico, index)"
                return-object
                busca-solicitaca-compra
                obrigatorio
              >
                <template v-slot:icon>
                  <v-icon
                    dark
                    color="red"
                    slot="prepend"
                    v-if="showDeleteButton && servicos.length > 1"
                    @click="servicoDeleteRow(index)"
                  >
                    mdi-minus
                  </v-icon>
                </template>
              </ServicoSelect>
            </v-col>
            <v-col md="4">
              <BemPatrimonialSelect
                :empresa="dadosGerais.empresa"
                :producao="dadosGerais.producao"
                v-model="servico.bemPatrimonial"
                :disabled="!servico.servico || allDisable"
                @change="servicoSelect(servico, index)"
                return-object
              />
            </v-col>

            <v-col md="2">
              <InputMoney
                outlined
                dense
                persistent-placeholder
                label="Valor Serviço (R$)"
                v-model="servico.valorUnitario"
                :disabled="
                !servico.servico ||
                allDisable || fornecedor.rpa
                "
                @change="atualizaTotalServico(servico, index)"
              />
            </v-col>
            <v-col md="auto" class="my-1">
              <v-btn
                  :disabled="allDisable || !fornecedor.numeroNotaFiscal"
                  icon
                  small
                  @click="servicoAddRow(index)"
              >
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
              <v-btn
                  :disabled="allDisable || !fornecedor.numeroNotaFiscal"
                  icon
                  small
                  @click="servicoDeleteRow(index)"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-title>
          Totais
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col class="col-2">
              <InputMoney
                outlined
                dense
                persistent-placeholder
                label="Outras Despesas (R$)"
                v-model="totais.outras_despesas"
                :disabled="!dadosGerais.producao || allDisable || fornecedor.rpa"
              />
            </v-col>
            <v-col class="col-2">
              <InputMoney
                outlined
                dense
                persistent-placeholder
                label="Total Geral (R$)"
                v-model="totais.totalGeral"
                disabled
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Barra de Ações -->
      <v-card class="mb-5">
        <v-card-text>
          <v-row class="p-2" justify="end">
             <v-btn
                class="mr-3"
                depressed
                outlined
                color="primary"
                @click="limparForm"
                :disabled="!cadastrado"
            >
              Nova Ordem
            </v-btn>
            <v-btn
              depressed
              color="primary"
              @click="handleFormSubmit"
              :disabled="allDisable"
            >
              Solicitar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data: function () {
    return {
      dadosGerais: {
        numeroOrdemCompra: 0,
        dataSolicitacao: this.$date.getISOToday(),
        empresa: 0,
        producao: 0,
        tipoDocumento: '',
        condicoesPagamento: null,
        situacao: '',
        entregaTipo: null,
        dataEmissaoNF: null,
        dataVencimentoNF: null,
        observacoes: null,
      },

      responsaveis: {
        solicitacao: null,
        compra: null,
        aprovacaoNv1: null,
        aprovacaoNv2: null,
      },

      fornecedor: {
        fornecedor: null,
        numeroNotaFiscal: null,
        rpa: null,
      },

      showDeleteButton: false,

      servicos: [
        {
          servico: null,
          bemPatrimonial: { id: '', label: '' },
          quantidade: 1,
          unidade: null,
          valorUnitario: null,
          total: null,
        },
      ],

      totais: {
        totalGeral: 0,
        outras_despesas: 0,
      },

      // Disable
      valorUnitarioDisabled: true,
      allDisable: false,
      cadastrado: false,

      condicoesDePagamentoOptions: [
        { id: '0001', label: 'À Vista' },
        { id: '0101', label: '1 PARC. (01 DIA PRAZO SEM ENTRADA)' },
        { id: '0105', label: '1 PARC. (05 DIAS PRAZO SEM ENTRADA)' },
        { id: '0107', label: '1 PARC. (07 DIAS PRAZO SEM ENTRADA)' },
        { id: '0110', label: '1 PARC. (10 DIAS PRAZO SEM ENTRADA)' },
        { id: '0115', label: '1 PARC. (15 DIAS PRAZO SEM ENTRADA)' },
        { id: '0120', label: '1 PARC. (20 DIAS PRAZO SEM ENTRADA)' },
        { id: '0121', label: '1 PARC. (21 DIAS PRAZO SEM ENTRADA)' },
        { id: '0125', label: '1 PARC. (25 DIAS PRAZO SEM ENTRADA)' },
        { id: '0128', label: '1 PARC. (28 DIAS PRAZO SEM ENTRADA)' },
        { id: '0130', label: '1 PARC. (30 DIAS PRAZO SEM ENTRADA)' },
        { id: '0135', label: '1 PARC. (35 DIAS PRAZO SEM ENTRADA)' },
        { id: '0140', label: '1 PARC. (40 DIAS PRAZO SEM ENTRADA)' },
        { id: '0145', label: '1 PARC. (45 DIAS PRAZO SEM ENTRADA)' },
        { id: '0160', label: '1 PARC. (60 DIAS PRAZO SEM ENTRADA)' },
        { id: '0190', label: '1 PARC. (90 DIAS PRAZO SEM ENTRADA)' },
        { id: '0215', label: '2 PARC. (15 DIAS PRAZO SEM ENTRADA)' },
        { id: '0220', label: '2 PARC. (20 DIAS PRAZO SEM ENTRADA)' },
        { id: '0225', label: '2 PARC. (25 DIAS PRAZO SEM ENTRADA)' },
        { id: '0228', label: '2 PARC. (28 DIAS PRAZO SEM ENTRADA)' },
        { id: '0230', label: '2 PARC. (30 DIAS PRAZO SEM ENTRADA)' },
        { id: '0245', label: '2 PARC. (45 DIAS PRAZO SEM ENTRADA)' },
        { id: '1210', label: '2 PARC. (ENTRADA + 10 DIAS PRAZO)' },
        { id: '1215', label: '2 PARC. (ENTRADA + 15 DIAS PRAZO)' },
        { id: '1220', label: '2 PARC. (ENTRADA + 20 DIAS PRAZO)' },
        { id: '1225', label: '2 PARC. (ENTRADA + 25 DIAS PRAZO)' },
        { id: '1228', label: '2 PARC. (ENTRADA + 28 DIAS PRAZO)' },
        { id: '1230', label: '2 PARC. (ENTRADA + 30 DIAS PRAZO)' },
        { id: '1245', label: '2 PARC. (ENTRADA + 45 DIAS PRAZO)' },
        { id: '0315', label: '3 PARC. (15 DIAS PRAZO SEM ENTRADA)' },
        { id: '0320', label: '3 PARC. (20 DIAS PRAZO SEM ENTRADA)' },
        { id: '0325', label: '3 PARC. (25 DIAS PRAZO SEM ENTRADA)' },
        { id: '0328', label: '3 PARC. (28 DIAS PRAZO SEM ENTRADA)' },
        { id: '0330', label: '3 PARC. (30 DIAS PRAZO SEM ENTRADA)' },
        { id: '1315', label: '3 PARC. (ENTRADA + 15 DIAS PRAZO)' },
        { id: '1320', label: '3 PARC. (ENTRADA + 20 DIAS PRAZO)' },
        { id: '1325', label: '3 PARC. (ENTRADA + 25 DIAS)' },
        { id: '1328', label: '3 PARC. (ENTRADA + 28 DIAS PRAZO)' },
        { id: '1330', label: '3 PARC. (ENTRADA + 30 DIAS PRAZO)' },
        { id: '0425', label: '4 PARC. (25 DIAS PRAZO SEM ENTRADA)' },
        { id: '0430', label: '4 PARC. (30 DIAS PRAZO SEM ENTRADA)' },
        { id: '1425', label: '4 PARC. (ENTRADA + 25 DIAS PRAZO)' },
        { id: '1430', label: '4 PARC. (ENTRADA + 30 DIAS PRAZO)' },
        { id: '0530', label: '5 PARC. (30 DIAS PRAZO SEM ENTRADA)' },
        { id: '1530', label: '5 PARC.(30 DIAS PRAZO SEM ENTRADA)' },
        { id: '0630', label: '6 PARC. (30 DIAS PRAZO SEM ENTRADA)' },
      ],

      situacaoOptions: [
        { id: 'P', label: 'Executada' },
        { id: 'V', label: 'Validada' },
      ],

      tipoDocumentoOptions: [
        { id: '0', label: 'NF' },
        { id: '1', label: 'RPA' },
      ],

      validacaoForm: {
        empresa: [(v) => !!v || 'Empresa é obrigatória'],
        producao: [(v) => !!v || 'Produção é obrigatória'],
        bemPatrimonial: [(v) => !!v || 'Bem Patrimonial é obrigatório'],
        situacao: [(v) => !!v || 'Situação é obrigatória'],
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  watch: {
    'totais.outras_despesas': function () {
      this.atualizaTotalGeral()
    },
    'fornecedor.rpa': function () {
      this.dadosGerais.dataEmissaoNF = this.fornecedor.rpa.data_emissao
      this.fornecedor.numeroNotaFiscal = null
      this.servicos[0].valorUnitario = this.fornecedor.rpa.valor
      this.servicos = this.servicos.slice(0, 1)
    },
  },

  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Serviços' },
      { title: 'Ordem de Serviços', route: 'ordem-servicos' },
    ])

    this.initialize()
  },
  methods: {
    initialize () {
      this.dadosGerais.situacao = 'P'
      const user = this.$store.getters.currentUser
      this.responsaveis.solicitacao = user.name.toUpperCase()
    },
    limparForm () {
      window.location.reload()
    },

    handleShowDelete () {
      this.showDeleteButton = !this.showDeleteButton
    },

    servicoAddRow (index) {
      if (!this.dadosGerais.producao) {
        this.$refs.snackbar.show('Alerta', 'Você deve selecionar uma Empresa e Produção', 'alert')
        return
      }

      this.servicos.splice(index+1, 0, {
        servico: null,
        bemPatrimonial: { id: '', label: '' },
        codigoFabricacao: null,
        modelo: null,
        quantidade: 1,
        unidade: null,
        valorUnitario: null,
        total: null,
      })
    },

    servicoDeleteRow (index) {
      this.servicos.splice(index, 1)

      if (this.servicos.length === 0) {
        this.servicoAddRow()
      }
    },

    servicoSelect (item, index) {
      if (!item.servico) {
        this.servicos[index].unidade = null
        return
      }

      this.servicos[index].unidade = item.servico.unidade
    },

    atualizaTotalServico (item, index) {
      if (item.valorUnitario) {
        this.servicos[index].total = this.$stringFormat.formatNumber(this.$stringFormat.convertToFloatNumber(item.valorUnitario) * item.quantidade)
        this.atualizaTotalGeral()
      }
    },

    atualizaTotalGeral () {
      let total = this.$stringFormat.convertToFloatNumber(this.totais.outras_despesas)

      for (const i in this.servicos) {
        total += this.$stringFormat.convertToFloatNumber(this.servicos[i].total)
      }

      this.totais.totalGeral = this.$stringFormat.formatNumber(total)
    },

    adicionaSeteDiasVencimentoNF (date) {
      const d = new Date(date)
      d.setDate(d.getDate() + 8)
      return this.$stringFormat.dateToISO(d.toLocaleString())
    },

    handleFormSubmit () {
      if (this.$refs.form.validate()) {
        this.$refs.snackbar.show(
          'Aguarde',
          'Realizando a Ordem de Serviço',
          'success',
          -1,
          true,
        )

        const servicos = []

        for (const servico of this.servicos) {
          servicos.push({
            servico_id: servico.servico.id,
            quantidade: servico.quantidade,
            valor: this.$stringFormat.convertToFloatNumber(servico.valorUnitario),
            bem_patrimonial: servico.bemPatrimonial ? servico.bemPatrimonial.id : '',
          })
        }

        ApiService
          .post('/sapiens/ordem-servico/criar-ordem-servico', {
            empresa_id: this.dadosGerais.empresa,
            producao_id: this.dadosGerais.producao,
            contratacao: this.responsaveis.compra,
            data_vencimento_nf: this.dadosGerais.dataVencimentoNF,
            data_nf: this.dadosGerais.dataEmissaoNF,
            tipo_documento: this.dadosGerais.tipoDocumento === '0' ? 'NF' : 'RPA',
            numero_documento: this.dadosGerais.tipoDocumento === '0' ? this.fornecedor.numeroNotaFiscal : this.fornecedor.rpa.id,
            condicao_pagamento: this.dadosGerais.condicoesPagamento,
            observacao: this.dadosGerais.observacoes,
            fornecedor_id: this.fornecedor.fornecedor,
            outras_despesas: this.totais.outras_despesas,
            servicos,
          })
          .then((res) => {
            if (res.data.data.numero === 0) {
              this.$refs.snackbar.show(
                'Erro',
                res.data.data.msg,
                'danger',
              )

              return
            }

            this.allDisable = true

            this.$refs.snackbar.show(
              'Sucesso',
              `Ordem de Serviço realizada com sucesso, número ${res.data.data.numero}`,
              'success',
            )

            this.dadosGerais.numeroOrdemCompra = res.data.data.numero

            this.cadastrado = true
          })
          .catch((error) => {
            this.$refs.snackbar.show(
              'Erro',
              'Não foi possível realizar a Ordem de Serviço',
              'danger',
            )

            console.error('Erro: ', error)
          })
      }
    },
  },
}
</script>

<style>
.v-input--selection-controls .v-radio > .v-label {
  margin-bottom: 0;
}
.v-input--selection-controls.v-input {
  margin-top: 0;
  padding-top: 0;
}
</style>
